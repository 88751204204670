import { Injectable } from '@angular/core';
import { ServiceModel, ServiceRequestModel } from '../_models/service.model';
import { HttpMethodsService } from './http-methods.services';
import { map, Observable } from 'rxjs';
import { PositionModel } from '../../layout/dialog-sort-list/dialog-sort-list.component';

@Injectable({
  providedIn: 'root',
})
export class ServicesService {
  constructor(private _httpService: HttpMethodsService) { }

  findOne(service_id: string): Observable<ServiceModel> {
    return this._httpService.get(`service`, { service_id }).pipe(
      map((res: any) => new ServiceModel(res))
    );
  }
  findAll(filters: any = {}) {
    return this._httpService.get('services', {}, filters).pipe(
      map((res: any) => {
        return {
          data: res?.data.map((r: any) => new ServiceModel(r)),
          total: res?.total,
        };
      })
    );
  }
  create(data: ServiceRequestModel) {
    return this._httpService.post('services', data);
  }

  update(service_id: string, data: ServiceRequestModel) {
    return this._httpService.patch('service', data, { service_id });
  }

  remove(service_id: string) {
    return this._httpService.delete('service', { service_id });
  }

  findDurationsAll() {
    return this._httpService.get(
      'values',
      {},
      { table_code: 'DURATION_SERVICE' }
    );
  }

  findSalesPersonsAll() {
    return this._httpService.get('service', { service_id: 'sales-person' });
  }

  updateOrder(data: readonly PositionModel[]) {
    return this._httpService.patch('positions', data, {});
  }
}
